<template>
  <TheNuxtLink
    :to="parsedTitle.href + (source ? `?s=${source}` : '')"
    class="block"
    :class="{ 'mb-12': !hideTitle }"
  >
    <AsyncImage
      class="w-full aspect-[5/7] object-cover rounded-md select-none"
      :src="parsedTitle.coverUrl"
      :alt="parsedTitle.title"
    />
    <p
      class="absolute left-0 mt-1 h-10 transition-opacity duration-300 delay-200 w-full line-clamp-2 text-sm"
      :class="{
        'opacity-0 pointer-events-none !delay-0': hideTitle,
      }"
    >
      {{ parsedTitle.title }}
    </p>
  </TheNuxtLink>
</template>

<script setup lang="ts">
import type { TitleEntity } from "~/src/api";
import {
  isParsed,
  parseTitleForDisplay,
  type ParsedTitleOutput,
} from "~/utils/display/title";

const props = defineProps<{
  title: TitleEntity | ParsedTitleOutput;
  hideTitle?: boolean;
  // optional source appended to route for analytics (s=_)
  source?: string;
}>();

const parsedTitle = computed(() => {
  if (isParsed(props.title)) {
    return props.title;
  }

  return parseTitleForDisplay(props.title, "med");
});
</script>
